<template>
  <div class="bancolombia">
    <div class="bancolombia-container">
         <div>
      <b-card-group deck>
        <b-card >
          <b-card-text >
            <h4>Te damos la bienvenida a GMV</h4>
            Carga tus archivos "Contener", "Revolvente", " Desmontes" y
            demás aquí...
          </b-card-text>
          <template #footer>
            ><Button v-b-modal.uploadModal type="submit" class="upload-button"
              ><i
                class="fas fa-upload fa-1x"
                style="color: white; margin: 5px"
              ></i>
              <br />
              <span class="nav--item--name">Cargar</span></Button
            >
          </template>
        </b-card>
      </b-card-group>
    </div>
      <div>
        <AdminProcessModal
          v-if="$route.path.toLowerCase().includes('administrador')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AdminProcessModal from "@/components/AdminProcessModal.vue";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
export default {
  name: "bancolombia",
  data() {
    return {
      modalShow: false,
    };
  },
  components: {
    AdminProcessModal,
    FileUpload,
    Button,
  },
};
</script>
<style lang="scss" scoped>
@include upload-button;
::v-deep .card-text:last-child {
    color: black;
    margin: 45px;
}
::v-deep .pi-upload:before {
  margin: 5px;
}
.bancolombia-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5rem;
}

</style>